/* eslint-disable */
// import { loadDictItemByKey } from '@/api/dict'
const getDefaultState = () => {
    return {
        // 字典map
        dictMap: {
            "dict_job_type":[{"itemKey":"0","itemName":"Full Time"},{"itemKey":"1","itemName":"Part Time"},{"itemKey":"2","itemName":"Internship"}],
            "dict_experience_level":[{"itemKey":"0","itemName":"Entry Level (New Grad)"},{"itemKey":"2","itemName":"Junior (1-2 years)"},{"itemKey":"3","itemName":"Mid-Level (3-4 years)"},{"itemKey":"4","itemName":"Seniro (5-8 years)"},{"itemKey":"5","itemName":"Expert & Leadership (9+ years)"}],
            "dict_profile_status":[{"itemKey":"0","itemName":"Public"},{"itemKey":"1","itemName":"Private"}],
            "dict_yon":[{"itemKey":"Y","itemName":"是"},{"itemKey":"N","itemName":"否"}],
            "dict_query_source":[{"itemKey":"sql","itemName":"sql查询"},{"itemKey":"rest","itemName":"远程调用"}],
            "dict_show_type":[{"itemKey":"text","itemName":"文本"},{"itemKey":"select","itemName":"下拉"}],
            "dict_column_type":[{"itemKey":"java.lang.String","itemName":"字符串"},{"itemKey":"java.lang.Integer","itemName":"整型"}],
            "dict_combo_user_type":[{"itemKey":"sdar","itemName":"发起部门+角色"},{"itemKey":"sdap","itemName":"发起部门+岗位"},{"itemKey":"odar","itemName":"办理部门+角色"},{"itemKey":"odap","itemName":"办理部门+岗位"},{"itemKey":"zdar","itemName":"指定部门+角色"},{"itemKey":"zdap","itemName":"指定部门+岗位"}],
            "dict_request_method":[{"itemKey":"GET","itemName":"GET"},{"itemKey":"POST","itemName":"POST"}],
            "dict_submit_way":[{"itemKey":"FORM","itemName":"FORM提交"},{"itemKey":"JSON","itemName":"JSON提交"}],
            "dict_menu_type":[{"itemKey":"MANUAL","itemName":"手动填写"},{"itemKey":"QUERY","itemName":"选择查询"}],
            "dict_org_type":[{"itemKey":"BRANCH","itemName":"分支"},{"itemKey":"DEPT","itemName":"部门"}],
            "dict_api_field_type":[{"itemKey":"STRING","itemName":"文本"},{"itemKey":"NUMBER","itemName":"数字"},{"itemKey":"OBJECT","itemName":"对象"},{"itemKey":"ARRAY","itemName":"数组"}]
        }
    }
}

const state = getDefaultState()

const mutations = {
    // 保存字典项
    SAVE_DICT_ITEM: (state, data) => {
        var obj = {}
        obj[data.dictKey] = data.items
        // 需要拷贝一份，要不然数据变动监听不到
        state.dictMap = Object.assign({}, state.dictMap, obj)
    },
    // 移除字典项
    DELETE_DICT_ITEM: (state, dictKey) => {
        delete state.dictMap[dictKey]
    }
}

const actions = {
    // 获取字典的action
    getByDictKey({ commit }, data) {
        return new Promise((resolve, reject) => {
            if (state.dictMap[data.dictKey]) {
                resolve()
            } else {
                // // 防止同一个key多次请求
                // commit('SAVE_DICT_ITEM', [])
                // // 这里暂不用api.service.js
                // loadDictItemByKey(data.dictKey).then(res => {
                //     if (res.data) {
                //         var obj = {}
                //         obj.dictKey = data.dictKey
                //         obj.items = res.data
                //         commit('SAVE_DICT_ITEM', obj)
                //     } else {
                //         commit('DELETE_DICT_ITEM', data.dictKey)
                //     }
                //     resolve()
                // }).catch(error => {
                //     commit('DELETE_DICT_ITEM', data.dictKey)
                //     reject(error)
                // })
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}