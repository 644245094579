import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('../views/jobs/Index.vue')
  },
  {
    path: '/jobDetail',
    name: 'jobDetail',
    component: () => import('../views/jobs/Detail.vue')
  },
  {
    path: '/jobPost',
    name: 'jobPost',
    component: () => import('../views/jobs/Post.vue')
  },
  {
    path: '/talent',
    name: 'talent',
    component: () => import('../views/talent/Index.vue')
  },
  {
    path: '/talentDetail',
    name: 'talentDetail',
    component: () => import('../views/talent/Detail.vue')
  },
  {
    path: '/myProfile',
    name: 'myProfile',
    component: () => import('../views/profile/Index.vue')
  },
  {
    path: '/editProfile',
    name: 'editProfile',
    component: () => import('../views/profile/Edit.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
