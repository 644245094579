import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import store from './store'
import router from './router'
import Web3 from 'web3'
import Clipboard from 'clipboard';

import '@/assets/font.css';

//注册到vue原型上
Vue.prototype.Clipboard = Clipboard;
Vue.prototype.Web3 = Web3

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
